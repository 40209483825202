// @ts-nocheck
/* eslint-enable */
// App
import React from 'react';
import { useSelector } from 'react-redux';
import { yieldCallback } from '@zillow/yield-callback';
import PropTypes from 'prop-types';

// Hooks
import useBreadcrumbs from 'app/shared/modules/BreadcrumbNav/useBreadcrumbs';

// Misc / utils
import * as S from './styles';

const BreadcrumbNav = ({ identifier = 'default' }) => {
  // Redux
  const slug = useSelector((state) => state.filter.search.slug);
  const areaCrumbs = useSelector((state) => state?.area?.area?.breadcrumbs);
  const currentListingCrumbs = useSelector((state) => state?.currentListingDetails?.currentListing?.areas?.breadcrumbs);

  // Hooks
  const [crumbs] = useBreadcrumbs(areaCrumbs, currentListingCrumbs, slug);

  const yieldTransitionTo = yieldCallback((crumbPath) => {
    window.router.transitionTo(crumbPath);
  });

  const handleCrumbClick = (e, crumbPath) => {
    // Prevent the browser from going through with its default <a> behavior
    e.preventDefault();

    yieldTransitionTo(crumbPath);
  };

  return (
    <S.BreadcrumbNavContainer aria-label={`breadcrumbs-${identifier}`}>
      <S.BreadcrumbNavOrderedList>
        {crumbs &&
          crumbs.map((crumb, idx) => {
            const currentPage = idx === crumbs.length - 1;
            const crumbPath = crumb.uri;
            return (
              <S.ListItem key={`breadcrumb-${crumb.name}`}>
                <S.BreadcrumbLinker
                  to={crumbPath}
                  onClick={(e) => handleCrumbClick(e, crumbPath)}
                  linkType="accent"
                  tabIndex={0}
                  aria-label={`Link to ${crumb.aria ? crumb.aria : crumb.name} page`}
                  aria-current={currentPage ? 'page' : null}
                >
                  {crumb.name}
                </S.BreadcrumbLinker>
              </S.ListItem>
            );
          })}
      </S.BreadcrumbNavOrderedList>
    </S.BreadcrumbNavContainer>
  );
};

BreadcrumbNav.propTypes = {
  identifier: PropTypes.string,
};

export default BreadcrumbNav;
